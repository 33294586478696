<template>
  <section class="edit-category">
    
    <div class="page-header">
      <h3 class="page-title"> Products </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0">
          <li class="breadcrumb-item">
            <router-link to="/catalog/products" class="text-primary">Product
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add Product</li>
        </ol>
      </nav>
    </div>
    <form class="pt-2" @submit.prevent="addProduct">
      <div class="row">
        <div class="col-lg-8 ">
          <div class="card mb-3">
            <div class="card-body">
              <div>
                <label class=" fs-12 p-0 m-0 col-form-label" for="name">Name
                </label>
                <div class="form-group">
                  <input type="text" v-model="add_product.name" class="form-control" :class="{
                    'is-invalid': submitted && $v.add_product.name.$error,
                  }" />
                  <div v-if="submitted && !$v.add_product.name.required" class="invalid-feedback">
                    Name is required
                  </div>
                </div>
              </div>
              <div class="form-group  mb-1">
                <label class=" m-0 p-0 fs-12 col-form-label" for="name">Price </label>

                <div class="form-group">
                  <input type="text" v-model="add_product.price" class="form-control" :class="{
                    'is-invalid': submitted && $v.add_product.price.$error,
                  }" />
                  <div v-if="submitted && $v.add_product.price.$error" class="invalid-feedback">
                    <span v-if="!$v.add_product.price.required">
                      Price is required</span>
                    <span v-if="!$v.add_product.price.between">
                      Must be between 0 and 999999
                    </span>
                  </div>
                </div>

              </div>
              <div>
                <div class="form-group   mb-1">
                  <label class=" fs-12  p-0 m-0  col-form-label" for="name">Description
                  </label>
                  <div class="form-group">
                    <vue-editor v-model="add_product.description" id="content" name="content"></vue-editor>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 ">
          <div class="card mb-3">
            <div class="card-body">



              <div class="form-group"> <label for=""><b class=" ">Product Organization </b></label><br>
                <label for="">Brand</label>
                <select class="custom-select form-control" v-model="add_product.brand_id" :class="{
                  'is-invalid': submitted && $v.add_product.brand_id.$error,
                }">
                  <option value="" selected>Select Brand</option>
                  <option v-for="brandsList in brandsList" :key="brandsList.id" v-bind:value="brandsList.id">
                    {{ brandsList.name }}
                  </option>
                </select>
                <div v-if="submitted && !$v.add_product.brand_id.required" class="invalid-feedback">
                  Brand is required
                </div>
              </div>

              <div class="form-group">
                <label for="">Categories </label>
                <treeselect v-model="add_product.categories_id" :multiple="true" :options="options" :flat="true"
                  :default-expand-level="1" :class="{
                    'is-invalid':
                      submitted && $v.add_product.categories_id.$error,
                  }" />
                <div v-if="submitted && !$v.add_product.categories_id.required" class="invalid-feedback">
                  Categories is required
                </div>
              </div>

              <div class="form-group">
                <label for="">Store</label>
                <multiselect v-model="store_id" :multiple="true" :options="storesList" label="name" track-by="name">
                </multiselect>
              </div>

              <div class="form-group">
                <label for="">Tags</label>
                <multiselect v-model="tags_id" :multiple="true" :options="tagsList" label="name" track-by="name">
                </multiselect>
              </div>


              <div class="form-group  ">
                <label for=""> Status </label>
                <div class="form-group  ">
                  <label class="switch">
                    <input type="checkbox" v-model="add_product.active" />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-lg-6 ">
          <div class="card mb-3">
            <div class="card-body">
              <div class="project-grid d-flex border mb-1">
                <div class="wrapper pl-2 pb-2 pt-2 w-100">
                  <div class="wrapper d-flex align-items-center">
                    <div class="wrapper">
                      <h5 class="project-title pb-0 mb-0">Related Products</h5>
                    </div>
                    <relatedProduct @relatedProductId="relatedProductName"></relatedProduct>

                    <b-button v-b-modal.modal-relatedProduct
                      class="badge badge-success ml-auto text-capitalize float-right mr-2" style="font-size: 11px">
                      Select Products</b-button>
                  </div>
                </div>
              </div>

              <div class="project-grid border">
                <h4 class="card-title p-2" block v-b-toggle.accordion-1 style="font-size: 14px !important">
                  Related Products in Product
                  <i class="mdi mdi mdi-plus menu-icon float-right"></i>
                </h4>

                <b-collapse visible id="accordion-1" accordion="my-accordion" role="tabpanel">
                  <div class="table-responsive w-100 mb-0">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody v-for="relatedProductId in relatedProductId" :key="relatedProductId.id">
                        <tr>
                          <td>
                            {{ relatedProductId.name }}
                          </td>
                          <td>
                            {{
                              relatedProductId.price.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="col-12 p-3 text-center font-size-12" v-if="relatedProductId == ''">
                      No Related Products found this Product
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 ">
          <div class="card mb-3">
            <div class="card-body">
              <div class="project-grid d-flex border   mb-1">
                <div class="wrapper pl-2 pb-2 pt-2 w-100">
                  <div class="wrapper d-flex align-items-center">
                    <div class="wrapper">
                      <h5 class="project-title pb-0 mb-0">Recommended Products</h5>
                    </div>
                    <recommendedProduct @recommendedProductId="recommendedProductName"></recommendedProduct>

                    <b-button v-b-modal.modal-recommendedProduct
                      class="badge badge-success ml-auto text-capitalize float-right mr-2" style="font-size: 11px">
                      Select Products</b-button>
                  </div>
                </div>
              </div>

              <div class="project-grid border">
                <h4 class="card-title p-2" block v-b-toggle.accordion-1 style="font-size: 14px !important">
                  Recommended Products in Product
                  <i class="mdi mdi mdi-plus menu-icon float-right"></i>
                </h4>

                <b-collapse visible id="accordion-1" accordion="my-accordion" role="tabpanel">
                  <div class="table-responsive w-100 mb-0">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Price</th>
                          <!-- <th>Action</th> -->
                        </tr>
                      </thead>
                      <tbody v-for="recommendedProductId in recommendedProductId" :key="recommendedProductId.id">
                        <tr>
                          <td>
                            {{ recommendedProductId.name }}
                          </td>
                          <td>
                            {{
                              recommendedProductId.price.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            }}
                          </td>
                          <!-- <td>
                                <a
                                  v-on:click="removeElement(recommendedProductId.id)"
                                  class="table-icon"
                                  v-b-tooltip.hover
                                  title="Delete"
                                >
                                  <i class="mdi mdi-delete"></i>
                                </a>
                              </td> -->
                        </tr>
                      </tbody>
                    </table>
                    <div class="col-12 p-3 text-center font-size-12" v-if="recommendedProductId == ''">
                      No Recommended Products found this Product
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 ">
          <div class="card mb-3">
            <div class="card-body">
              <div class="form-group">
                <label for="">
                  <b>Media</b>
                </label>
                <b-button v-b-modal.modal-xl class="choose-image">Choose Image</b-button>

                <chooseImageProduct @imageId="setImageId"></chooseImageProduct>
                <vue-dropzone ref="myVueDropzone" id="thumbVideo " :options="dropzoneOptions" :useCustomSlot="true"
                  v-on:vdropzone-success="success" @click="removeThisFile(UploadFile)" @vdropzone-sending="sendImages">
                  <div class="dropzone-custom-content">
                    <div class="dropzone-custom-content">
                      <div v-if="bannerImg != ''">
                        <div class="image-size-dropzone" v-for="bannerImg in bannerImg" :key="bannerImg.id">
                          <img :src="`${imageApiURL}/${bannerImg.thumb_s}`" class="show-img img-fluid" />
                        </div>
                        <div class="subtitle pt-1 pb-0">
                          <span> Change </span>
                          <span style="color: #eb2027"> Browse</span>
                        </div>
                      </div>
                      <div v-if="bannerImg == ''" class="pt-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 24 24"
                          fill="transparent" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="feather feather-upload" color="">
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="17 8 12 3 7 8"></polyline>
                          <line x1="12" y1="3" x2="12" y2="15"></line>
                        </svg>
                        <div class="subtitle pt-1 pb-5">
                          <span> Drop File or </span>
                          <span style="color: #eb2027"> Browse</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </vue-dropzone>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group float-right mt-3">
            <button class="btn btn btn-add btn-primary">Submit</button>
          </div>
        </div>

      </div>
    </form>
    <!-- <div class="project-grid  border-bottom">
      <h4 class="card-title py-2 px-0 " block v-b-toggle.accordion-3 style="font-size: 19px !important">
        Recommended Products in Product
        <i class="mdi mdi mdi-plus menu-icon float-right"></i>
      </h4>
      <b-collapse visible id="accordion-3" accordion="my-accordion" role="tabpanel">
        <div class="table-responsive w-100 mb-0">
          <div class="col-12 p-3 text-center font-size-12">
          </div>
        </div>
      </b-collapse>
    </div> -->
  </section>
</template>

<script>
import axios from "axios";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
import Treeselect from "@riophae/vue-treeselect";

import { required, between } from "vuelidate/lib/validators";
import { catalogApi } from "../../../api";
import Multiselect from "vue-multiselect";
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
import chooseImageProduct from "../../../components/chooseImageProduct";
import recommendedProduct from "../../../components/recommendedProduct";
import relatedProduct from "../../../components/relatedProduct";
import { VueEditor } from "vue2-editor";
export default {
  name: "add_product",
  components: {
    
    Multiselect,
    Treeselect,
    chooseImageProduct,
    recommendedProduct,
    relatedProduct,
    vueDropzone: vue2Dropzone,
    VueEditor,
  },
  data() {
    return {
      
      brandsList: "",
      imageApiURL,
      options: [],
      dropzoneOptions: {
        url: `${mediaApiURL}/file/upload`,
        maxFilesize: 10,
        addRemoveLinks: true
      },
      relatedProductId: [],
      recommendedProductId: [],
      images: [],
      imagesId: [],
      store_id: null,
      tags_id: null,
      store_empty: [],
      tags_empty: [],
      storesList: [],
      tagsList: [],
      add_product: {
        name: "",
        price: "",
        brand_id: "",
        categories_id: null,
        active: '',
      },
      bannerImg: [],
      submitted: false,
      //  selected: localStorage.getItem("imageId"),
      related_products: [],

    };
  },
  validations: {
    add_product: {
      name: { required },
      price: { required, between: between(0, 999999) },
      brand_id: { required },
      categories_id: { required },
    },
  },
  mounted() {
    this.getBrandsByList();
    this.allCategory();
    this.getStoresByList();
    this.getTagsByList();
  },
  methods: {
    async getBrandsByList() {
      
      const { data } = await catalogApi.getBrandsByList();
      this.brandsList = data.data.data;
      
      if (this.selected) {
        this.banner();
      }
    },
    removeThisFile: function (thisFile) {
      this.$refs.MyDropzone.removeFile(thisFile)
    },
    async allCategory() {
      
      const { data } = await catalogApi.allCategory();
      this.options = data.data.data;
      
    },

    async getTagsByList() {
      const { data } = await catalogApi.getTagsByList();
      this.tagsList = data.data.data;
    },

    async getStoresByList() {
      const { data } = await catalogApi.getStoresByList();
      this.storesList = data.data.data;
    },
    setImageId(selectedId) {
      this.imagesId = selectedId;
      selectedId.forEach((item) => {
        
        axios.get(`${mediaApi}/files/${item}`).then((response) => {
          let a = response.data.data[0];
          this.bannerImg.push(a)

          
        });
      });

    },
    relatedProductName(selectedId) {
      

      this.relatedProductId = selectedId;
      
    },
    recommendedProductName(selectedId) {
      

      this.recommendedProductId = selectedId;
      
    },
    removeElement: function (index) {
      this.recommendedProductId.id.splice(index, 1);
    },
    sendImages(file, xhr, formData) {
      let products = "products";
      xhr.setRequestHeader("Header", "");
      formData.append("folder", products);
    },

    success(file, response) {
      this.add_product.image_id = response.data.id;
      this.bannerImg = "";
      if (response.status == "success") {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },

    async addProduct() {
      this.submitted = true;
      this.store_empty = [];
      this.images_empty = [];
      this.tags_empty = [];
      this.relatedProduct_empty = [];
      this.recommendedProduct_empty = [];

      if (this.add_product.active === true) {
        this.add_product.active = 1;
      } else {
        this.add_product.active = 0;
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.imagesId) {
        this.imagesId.forEach((value) => {
          let item = value;
          if (!this.images_empty.includes(item))
            this.images_empty.push(item);
        });
        this.add_product.images = this.images_empty;
      }
      if (this.relatedProductId) {
        this.relatedProductId.forEach((value) => {
          let item = value.id;
          if (!this.relatedProduct_empty.includes(item))
            this.relatedProduct_empty.push(item);
        });
        this.add_product.related_products = this.relatedProduct_empty;
      }
      if (this.recommendedProductId) {
        this.recommendedProductId.forEach((value) => {
          let item = value.id;
          if (!this.recommendedProduct_empty.includes(item))
            this.recommendedProduct_empty.push(item);
        });
        this.add_product.recommended_products = this.recommendedProduct_empty;
      }
      if (this.bannerImg) {
        this.add_product.image_id = this.bannerImg.id;
      }

      if (this.store_id) {
        this.store_id.forEach((value) => {
          let store = value.id;
          if (!this.store_empty.includes(store)) this.store_empty.push(store);
        });
        this.add_product.store_id = this.store_empty;
      }

      if (this.tags_id) {
        this.tags_id.forEach((value) => {
          let tags = value.id;
          if (!this.tags_empty.includes(tags)) this.tags_empty.push(tags);
        });
        this.add_product.tags_id = this.tags_empty;
      }

      if (this.add_product.image_id) {
        this.add_product.image_id = String(this.add_product.image_id);
      }

      
      const data = await catalogApi.addProduct({
        ...this.add_product,
      });

      
      this.$router.push("/catalog/products");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>
<style scoped>
.dropzone {
  padding: 27px 20px !important;
}

.form-group label {
  text-align: right;
}

.form-group label span {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.form-control {
  border-radius: 4px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
